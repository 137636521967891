module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-NN3NTZMJ","cookieName":"gatsby-gdpr-google-tagmanager","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Exclusive Fitness","short_name":"Exclusive","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/images/logo-square.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cedad6fbc1512d50837586e6d4b7ad66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImV4Y2x1c2l2ZS1maXRuZXNzLTFlMzhjNWI2LTMxNWYtNDIzYy1hMGI1LTI3YTZmOTc5NTMxYV8zIiwiZGF0ZSI6MTYzMzk3ODE1MywiZG9tYWluIjoiZXhjbHVzaXZlLWZpdG5lc3MiLCJpYXQiOjE2MzM5NzgxNTN9.EhYglMtvn-2EIkxLTR72uEl9Au86wx2kynd4bikLUJs","repositoryName":"exclusive-fitness","routes":[{"type":"page","uid":"index","path":"/:lang/?"},{"type":"page","path":"/:lang?/:uid/"},{"type":"blogpost","path":"/:lang?/news/:uid/"},{"type":"team_member","path":"/:lang?/team/:uid/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
